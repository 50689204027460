/* You can add global styles to this file, and also import other style files */
@import "typeface-poppins";
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background: #0C1026 !important;
}
.screen {
    @apply md:max-w-screen-md lg:md:max-w-screen-lg xl:md:max-w-screen-xl mx-auto
}

.modal {
    @apply bg-white px-5 pt-20
    /* Add your sidebar styling here */
  }
  
  .overlay {
    opacity: 0;
    display: none;
    /* Add your overlay styling here */
  }
  
  .modalOpen {
    transform: translateX(-0%);
    opacity: 1;
    display: block;
  }

  .angular-editor-toolbar {
    background-color: #0C1026 !important;
    color: white;
  }
  .angular-editor-button {
    background-color: #0C1026 !important;
    color: white;
  }
  .select-custom-style {
    background-color: #0C1026 !important;
    color: white;
  }
  .angular-editor-textarea{
    color: white !important;
  }
  .ae-picker-label,
  .ae-picker-item {
    background-color: #0C1026 !important;
    color: white;
  }

  @layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  /* Dark Theme for CKEditor */
.ck-editor__editable {
  color: #fff  !important;
  background: #0C1026  !important;
  border: 1px solid #ccc  !important;
}

/* CKEditor Toolbar */
.ck-toolbar {
  background-color: #0C1026 !important;
  color: #fff  !important;
  
}

.ck.ck-button:hover  {
  background: #ffffFF0D !important;
}
.ck-button:active  {
  background: #ffffff0D !important;
}

.ck-button:active  {
  background: #ffffff0D !important;
}
.ck-button:active  {
  background: #ffffff0D !important;
}
.ck.ck-list__item {
  background: #000 !important;
}
.ck.ck-list__item:hover {
  background: #ffffff0d !important;
}
/* Selected Text */
.ck-editor__editable .ck-highlighted {
  background: #555  !important;
}
button {

  color: #fff  !important;

}

/* Border color for selected cells in the table */
.ck-editor__editable .ck-selected {
  border: 1px solid #555  !important;
}

/* Placeholder text */
.ck-editor__editable .ck-placeholder {
  color: #ccc  !important;
}

/* Active and Hover states for buttons */
.ck-toolbar a:hover,
.ck-toolbar a:active {
  background: #555 !important;
}
